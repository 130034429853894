import { ILang } from "@/components/Language/type";

export const Language: ILang[] = [
  {
    code: "en",
    id: 0,
    name: "English",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Us@3x.png",
  },
  {
    code: "zh-CN",
    id: 1,
    name: "简体中文",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Cn@3x.png",
  },
  {
    code: "es",
    id: 2,
    name: "Español",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Es@3x.png",
  },
  {
    code: "fr",
    id: 3,
    name: "Français",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Fr@3x.png",
  },
  {
    code: "de",
    id: 4,
    name: "Deutsch",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/De@3x.png",
  },
  {
    code: "vi",
    id: 5,
    name: "Tiếng Việt",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Vn@3x.png",
  },
  {
    code: "sr",
    id: 6,
    name: "Srpski",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Sr@3x.png",
  },
  {
    code: "sl",
    id: 7,
    name: "Slovenščina",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Si@3x.png",
  },
  {
    code: "lt",
    id: 8,
    name: "Lietuvių",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Lt@3x.png",
  },
  {
    code: "hr",
    id: 9,
    name: "Hrvatski",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Cr@3x.png",
  },
  {
    code: "et",
    id: 10,
    name: "Eesti",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Et@3x.png",
  },
  {
    code: "th",
    id: 11,
    name: "ภาษาไทย",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Th@3x.png",
  },
  {
    code: "ms",
    id: 12,
    name: "Bahasa Malaysia",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Ms@3x.png",
  },
  {
    code: "id",
    id: 13,
    name: "Bahasa Indonesia",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Id@3x.png",
  },
  {
    code: "lv",
    id: 14,
    name: "Latviski",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Lv@3x.png",
  },
  {
    code: "he",
    id: 15,
    name: "עברית",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Il@3x.png",
  },
  {
    code: "ko",
    id: 16,
    name: "한국어",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Kr@3x.png",
  },
  {
    code: "ro",
    id: 17,
    name: "Română",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Ro@3x.png",
  },
  {
    code: "bg",
    id: 18,
    name: "Български",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Bg@3x.png",
  },
  {
    code: "pl",
    id: 19,
    name: "Polski",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Pl@3x.png",
  },
  {
    code: "zh-TW",
    id: 20,
    name: "繁體中文",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Tw@3x.png",
  },
  {
    code: "sv",
    id: 21,
    name: "Svenska",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Sv@3x.png",
  },
  {
    code: "fi",
    id: 22,
    name: "Suomi",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Fi@3x.png",
  },
  {
    code: "cs",
    id: 23,
    name: "Čeština",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Cz@3x.png",
  },
  {
    code: "da",
    id: 24,
    name: "Dansk",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Dk@3x.png",
  },
  {
    code: "is",
    id: 25,
    name: "Íslenska",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Is@3x.png",
  },
  {
    code: "no",
    id: 26,
    name: "Norsk",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/No@3x.png",
  },
  {
    code: "it",
    id: 27,
    name: "Italiano",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/It@3x.png",
  },
  {
    code: "ur",
    id: 28,
    name: "اردو",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Pk@3x.png",
  },
  {
    code: "ar",
    id: 29,
    name: "العربية",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Sa@3x.png",
  },
  {
    code: "ru",
    id: 30,
    name: "Русский",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Ru@3x.png",
  },
  {
    code: "hi",
    id: 31,
    name: "हिन्दी",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/In@3x.png",
  },
  {
    code: "uk",
    id: 32,
    name: "Українська",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Ua@3x.png",
  },
  {
    code: "tr",
    id: 33,
    name: "Türkçe",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Tr@3x.png",
  },
  {
    code: "el",
    id: 34,
    name: "Ελληνικά",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Gr@3x.png",
  },
  {
    code: "hu",
    id: 35,
    name: "Magyar",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Hu@3x.png",
  },
  {
    code: "fil",
    id: 36,
    name: "Filipino",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Ph@3x.png",
  },
  {
    code: "pt-PT",
    id: 37,
    name: "Português - Portugal",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Pt@3x.png",
  },
  {
    code: "nl",
    id: 38,
    name: "Nederlands",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Nl@3x.png",
  },
  {
    code: "pt-BR",
    id: 39,
    name: "Português - Brasil",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Br@3x.png",
  },
  {
    code: "mn",
    id: 40,
    name: "Монгол",
    image:
      "https://t-cf.bstatic.com/design-assets/assets/v3.82.4/images-flags/Mn@3x.png",
  },
];
