import { gray, blackAlpha } from "./colors";

const lightTheme = {
  colors: {
    //semantic colors
    ...gray,
    ...blackAlpha,
    blue500: "#3182ce",

    // use case aliases
    primary1: "#9C6AFD",
    primary2: "#04E2AF",
    primary3: "#1A1C55",
    primary4: "#5570F1",
    secondary: "#EBF9FF",
    error: "#D95637",
    success: "#38A169",
    warning: "#DD6B20",
    textMenu: "#000",
    info: "#3182CE",
    inactive: "#A1A1A1",
    headerBorder: "#D7D7D790",
    toggleThumb: "#9C6AFD",
    inputPlaceholder: "#5B5B5B66",
    connectInput: "#9C6AFD",
    request: "#1A1C55",
    cardBorder: "#C1C1C1",
    red: "#E80B0B",
    red1: "#FF1B1B",
    red2: "#E35561",
    invert: "#000",
    //linkcolor
    textBlue1: "#176EF0",
    textBlue2: "#0019FD",
    lightblue1: "#EDFCFF",
    lightblue2: "#1869D7",
    // redefine tokens
    btnOutlineColor: "#6D6E71",
    linkBanner: "#F8F8F8",
    http: "#43B875",
    traderText: "#7e8c9f",
    priceText: "#01E2AF",
    forgotText: "#2DC2FB",
    googleBtnColor: "$white", //#4285f4
    connectBox: "#1B1773",
    bluebg: "#1B1773",
    checkListBox: "#F9F9F9",
    // text colors
    textDark: "#222222",
    textDark2: "#283C46",
    textDark3: "#1C2730",
    textDark4: "#082530",
    textBlue: "#1B1773",
    textGreen: "#26A17B",
    textGreen1: "#43B875",
    textGreen2: "#008D6D",
    textGreen3: "#01E2AF",
    textGray1: "#525252",
    textGray2: "#6D6E71",
    textGray3: "#7e8c9f",
    textGray4: "#797979",
    textGray5: "#23203F",
    textGray6: "#CCC",
    textGray7: "#3F3F3F",
    textGray8: "#6D6D6D",
    textGray9: "#373737",
    textGray10: "#5B5B5B",
    textGray11: "#8A8A8A",
    textGray12: "#D3D3D3",
    textGray13: "#EDEDED",
    textGray14: "#d6d6d6",
    textGray15: "#4D545C",
    textGray16: "#EFEFEF",
    textGray17: "#F5F5F5",
    textGray18: "#9E9E9E",
    textGray19: "#464646",
    textGray20: "#989898",
    borderGray: "#E9E9E9",
    borderGray2: "#CACACA",
    lineColor: "#E1E1E1",
    jobBoxBorder: "#9C6AFD33",
    jobContent: "#8F8F8F",
    jobRegion: "#BCBCBC",
    jobTypeBox: "#9c6afd1a",
    lineColor2: "#E1E1E133",
    symbolColor: "#4A6CD1",
    aboutText: "#4D545C",
    socialIcon: "$white",
    textYellow: "#FFC64D",
    lightGreen: "#04e2af33",
    // misc colors
    bodyBg: "$white",
    bodyText: "$textGray1",
    borderColor: "#bebebe",
    borderColor1: "#CCCCCC",
    placeholderBorder: "#D3D3D3",
    bgGradient: "126.13deg, #865CE6 -34.94%, #2F2488 93.99%",
    buttonGradient: "139.13deg, #9C6AFD 13.71%, #1B1773 76.8%",
    boxGradient:
      "107.3deg, #F2EEFE 0%, #F5FAFF 18.98%, #F6FCFF 63.26%, #E0ECFD 101.21%",
    connectButton: "101.43deg, #9C6AFD -9%, #1B1773 108.58%",
    footerBg: "#042A64",
    logoColor: "#1B1773",
    accordion: "#F0F0F0",
    minBuying: "#578B9E",
    errorBox: "#fefaf9",
    errorBorder: "#fcf0ed",
    label: "#10495F",
    missionIcon: "#1B1773",
    strokeColor: "#292D32",
    menuColor: "$white",
    primaryGradient: "134deg, #865CE6 0%, #2F2488 100%",
    balanceGradient:
      "107.3deg, #F2EEFE 0%, #F5FAFF 18.98%, #F6FCFF 63.26%, #E0ECFD 101.21%",
    statistics: "#222222",
    tableHeader: "#9C6AFD",
    tableBorder: "#D9D9D9",
    tableRow: "#F7F7F7",
    securityDisable: "#B7B7B7",
    disabledInput: "#F2F2F7",
    sendTab: "#F7F7F7",
    btcColor: "#F7931A",
    modalBg: "$white",
    offerBg: "0deg, #F7F7F7, #F7F7F7",
    offerHeading: "#000000",
    progressBg: "#D9D9D9",
    dropdownBg: "$white",
    selection: "#F8F8F8",
    lctColor: "#9D6AFC",
    marketTable: "#fff",
    landingBg: 'url("/images/landing/banner.webp") no-repeat',
    traderTop: "#ECFFFB",
    traderSvg: "#1B1773",
    toggle2Thumb: "#43B875",
    toggle3Thumb: "#7E8C9F",
    selectHover: "#DDFFF7",
    blogBg: "#04E2AF1a",
    inputCardBackground: "#FFFFFF",
    mediumSecurity: "#FBC55A",
    highSecurity: "#04E2AF",
    lowSecurity: "#E35561",
    toggle1Bg: "#49A3E9",
    toggle2Bg: "#808080",
    indicator: "#F2F2F7",
    warnIndi: "#FFEBEB",
    blogSide: "#F3F3F3",
    selectionBg: "$white",
    hireGradient:
      "107.3deg, #F2EEFE 0%, #F5FAFF 18.98%, #F6FCFF 63.26%, #E0ECFD 101.21%",
    currentColor: "black",
    chatSender: "#042A64",
    notiBg: "#F4F4F4",
    verifyCard:
      "linear-gradient(107.3deg, #f2eefe 0%, #f5faff 18.98%, #f6fcff 63.26%, #e0ecfd 101.21%)",
    inputText: "#F2F2F7",
  },
  shadows: {
    xs: "10px 10px 20px rgba(0, 0, 0, 0.07)",
    sm: "0px 0px 20px rgba(0, 0, 0, 0.1);",
    md: "0px 0px 20px rgba(0, 0, 0, 0.25)",
    lg: "4px 4px 20px rgba(0, 0, 0, 0.12)",
    xl: "0 40px 80px rgba(20, 20, 20, 0.25)",
    xxl: "inset 0 0 0 1px rgba(0,0,0,.1)",
  },
};

export default lightTheme;
