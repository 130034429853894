import Axios, { AxiosError } from "axios";
import { queryClient } from "./reactQuery";
import storage from "@/utils/storage";

const apiClient = Axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  // withCredentials : true
});

const cmsClient = Axios.create({
  baseURL: import.meta.env.VITE_API_CMS_BASE_URL,
});

apiClient.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const newConfig = { ...config }; // Clone the config object
    const jwtToken = localStorage.getItem("Authentication");
    if (jwtToken) {
      newConfig.headers.authentication = `${jwtToken}`;
    }
    return newConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error?.response?.data["statusCode"] === 401) {
      queryClient.clear();
      storage.clearToken();
      localStorage.clear();
      window.location.href = "/login";
      return;
    }
    return Promise.reject(error);
  }
);

export { apiClient, AxiosError, cmsClient };
