import { styled } from "@/theme/stitches.config";
import { Text } from "./Text";

export const Link = styled("a", {
  alignItems: "center",
  gap: "$1",
  flexShrink: 0,
  outline: "none",
  textDecorationLine: "none",
  textUnderlineOffset: "3px",
  textDecorationColor: "$slate4",
  WebkitTapHighlightColor: "rgba(0,0,0,0)",
  lineHeight: "inherit",
  "@hover": {
    "&:hover": {
      textDecorationLine: "underline",
    },
  },
  "&:focus": {
    outlineWidth: "2px",
    outlineStyle: "solid",
    outlineOffset: "2px",
    textDecorationLine: "none",
  },
  [`& ${Text}`]: {
    color: "inherit",
  },
  variants: {
    variant: {
      blue: {
        color: "$textBlue",
        textDecoration: "underline",
        "&:focus": {
          outlineColor: "none",
        },
      },
      subtle: {
        color: "$slate11",
        textDecorationColor: "$slate4",
        "&:focus": {
          outlineColor: "$slate8",
        },
      },
      contrast: {
        color: "$hiContrast",
        textDecoration: "underline",
        textDecorationColor: "$slate4",
        "@hover": {
          "&:hover": {
            textDecorationColor: "$slate7",
          },
        },
        "&:focus": {
          outlineColor: "$slate8",
        },
      },
    },
  },
  defaultVariants: {
    variant: "contrast",
  },
});
