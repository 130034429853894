//enum
export enum ThemeEnum {
  LIGHT = "theme-default",
  DARK = "dark-theme",
}

export enum TradeType {
  BUY = "Buy",
  SELL = "Sell",
}

export enum STATUS_ENUM {
  OFFLINE = "Offline",
  ONLINE = "Online",
}

export function countWords(str: string) {
  if (str.trim() === "") {
    return 0;
  }
  return str.length;
}

export const ImageSupportive = ["png", "jpeg", "jpg"];
export const videoSupportive = ["mp4", "mov", "quicktime"];
export const inactivityDuration = 8 * 60 * 1000; // 10 minutes in milliseconds

const colorList = [
  "#F2D24A",
  "#FF0000",
  "#030185",
  "#307967",
  "#029637",
  "#000000",
  "#030185",
  "#38ADF6",
  "#0137A3",
  "#C94E49",
];

export function getRandomColorWithConstraints(previousColor: string) {
  let availableColors = colorList.filter((color) => color !== previousColor);

  if (previousColor) {
    const prevIndex = colorList.indexOf(previousColor);
    if (prevIndex > 0) {
      availableColors = availableColors.filter(
        (_, index) => index !== prevIndex - 1
      );
    }
    if (prevIndex < colorList.length - 1) {
      availableColors = availableColors.filter(
        (_, index) => index !== prevIndex + 1
      );
    }
  }
  const randomIndex = Math.floor(Math.random() * availableColors.length);
  return availableColors[randomIndex];
}
export const maxSizeBytes = 50 * 1024 * 1024; // 50MB

export enum FAQS_POS {
  SEND = "SEND",
  RECEIVE = "RECEIVE",
  DASHBOARD = "DASHBOARD",
  MAIN = "MAIN",
  P2P = "P2P",
}

export enum SUPPORTED_ENUM {
  png = "png",
  jpeg = "jpeg",
  jpg = "jpg",
  mp4 = "mp4",
  pdf = "pdf",
  mov = "mov",
  doc = "doc",
  docx = "docx",
}

// export const supportedFileTypes: Record<string, string> = {
//   png: "image/png",
//   jpeg: "image/jpeg",
//   jpg: "image/jpg",
//   mp4: "video/mp4",
//   pdf: "application/pdf",
//   mov1: "video/quicktime",
//   mov: "video/mov",
//   doc: "application/doc",
//   docx: "application/docx",
//   "application/msword": "application/msword",
//   "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
//     "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
// };
export const supportedFileTypes: Record<string, string> = {
  png: "png",
  jpeg: "jpeg",
  jpg: "jpg",
  mp4: "mp4",
  pdf: "pdf",
  mov: "mov",
  doc: "doc",
  docx: "docx",
  // "application/msword": "application/msword",
  // "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
  //   "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
};

export const PAGE_ERRORS = {
  ERR_NETWORK: "ERR_NETWORK",
  401: 401,
  403: 403,
  404: 404,
  429: 429,
  500: 500,
  502: 502,
  503: 503,
  504: 504,
};

export const initialPhoneVerifyTime = 59; // Initial time in seconds
export const initialEmailVerifyTime = 59; // Initial time in seconds

export enum KYC_LEVELS {
  ONE = "1",
  TWO = "2",
  THREE = "3",
  NAN = "N/A",
}

export enum FEEDBACK {
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
  ALL = "ALL",
}

export enum DEVICE_STATUS_ENUM {
  ACTIVE = "Online",
  LOGGED_OUT = "Offline",
}

export enum COIN_COLORS {
  BTC = "#F7931A",
  BUSD = "#F0B90B",
  ETH = "#627EEA",
  BNB = "#F3BA2F",
  USDC = "#2775CA",
  USDT = "#26A17B",
  LCT = "#9D6AFC",
}

export enum MFA_SETTING_ENUM {
  LOGIN__MFA = "LOGIN__MFA",
  TRADING__MFA = "TRADING__MFA",
  WITHDRAWAL_MFA = "WITHDRAWAL_MFA",
  NOTIFICATION_SOUND = "NOTIFICATION_SOUND",
}

export enum INDICATORS_ENUM {
  APPEAL_OPENED = "APPEAL_OPENED",
  MARKED_RECEIVED = "MARKED_RECEIVED",
  MARKED_PAID = "MARKED_PAID",
  ORDER_CANCELED = "ORDER_CANCELED",
  ORDER_CANCELLED_AUTO = "ORDER_CANCELLED_AUTO",
  ORDER_CANCELLED = "ORDER_CANCELLED",
  DISPUTE_CANCELLED = "DISPUTE_CANCELLED",
  INSTRUCTION_ADDED = "INSTRUCTION_ADDED",
  ORDER_CANCELED_DISPUTE = "ORDER_CANCELED_DISPUTE",
  MARK_RECEIVED_DISPUTE = "MARK_RECEIVED_DISPUTE",
  MARKED_RECEIVED_DISPUTE = "MARKED_RECEIVED_DISPUTE",
  KEYWORD_DETECTION = "KEYWORD_DETECTION",
}

export const indicatorsSet = new Set(Object.values(INDICATORS_ENUM));

export enum MERCHANT_ENUM {
  "NON-MERCHANT" = "NON-MERCHANT",
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  UNDER_PROCESS = "UNDER_PROCESS",
  REJECTED = "REJECTED",
}

export enum MERCHANT_TEXT_ENUM {
  "NON-MERCHANT" = "Become a verified Merchant",
  PENDING = "Pending",
  APPROVED = "Approved",
  UNDER_PROCESS = "Under Process",
  REJECTED = "Rejected",
}
