import { createReducer } from "@reduxjs/toolkit";
import {
  setIsDirectRoute,
  setIsShowActivate,
  setP2pAction,
  setTokenList,
  setTokenLoading,
  setTokens,
} from "./actions";
import { ITokenState } from "./type";
import { P2P_ACTION } from "@/components/BuySaleWidget/type";

export const initialState: ITokenState = {
  tokenList: [],
  tokenInfo: {
    blockchainId: -1,
    cmcId: "",
    color: "",
    contractAddress: "",
    createdAt: "",
    decimals: -1,
    id: -1,
    imageUrl: "",
    isActive: false,
    isNative: false,
    name: "",
    symbol: "",
    updatedAt: "",
    balance: 0,
    label: "",
    token_symbol: "",
    amount: 0,
    blockchain: undefined,
    maxLimit: 0,
  },
  p2pAction: P2P_ACTION.BUY,
  isTokenLoading: false,
  isDirectRoute: false,
  isShowActivate: true,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setTokens, (state, { payload }) => {
      state.tokenInfo = payload;
    })
    .addCase(setP2pAction, (state, { payload }) => {
      state.p2pAction = payload;
    })
    .addCase(setTokenList, (state, { payload }) => {
      state.tokenList = payload;
    })
    .addCase(setTokenLoading, (state, { payload }) => {
      state.isTokenLoading = payload;
    })
    .addCase(setIsDirectRoute, (state, { payload }) => {
      state.isDirectRoute = payload;
    })
    .addCase(setIsShowActivate, (state, { payload }) => {
      state.isShowActivate = payload;
    })
);
