import React from "react";
import { Route, Routes } from "react-router-dom";
const Login = React.lazy(() => import("./Login"));
const Register = React.lazy(() => import("./Register"));

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="register" element={<Register />} />
      <Route path="login" element={<Login />} />
    </Routes>
  );
};
