import { Box, Flex } from "@/components/elements";
import { styled } from "@/theme";
import { Tooltip as ReactTooltip } from "react-tooltip";

export const MainWrapper = styled(Flex, {
  minHeight: "80vh",
  pb: "$20",
  background: "$selectionBg",
  ".inner-section": {
    width: "80%",
    m: "$space$4 auto",
    Col: {
      ".lottie-container": { width: "90%" },
    },
  },
});

export const ContentWrapper = styled(Flex, {
  linearGradient: "$offerBg",
  borderRadius: "$radii$xlg",
  border: "0.4px solid $connectInput",
  padding: "$4 $6",
  "@md": {
    padding: "$10",
  },
});

export const InnerBox = styled(Box, {
  w: "100%",
  background: "$dropdownBg",
  border: "1px solid #CBCBCB",
  padding: "$4 $4 $4 0",
  borderRadius: "$radii$xlg",
  mt: "$4",
  ".image-box": {
    svg: {
      w: "40px",
      h: "40px",
      "@lg": {
        width: "72px",
        height: "39px",
      },
    },
  },
  "@lg": {
    mt: "0",
    p: "$4",
  },
});

export const PriceBox = styled(Flex, {
  width: "280px",
  borderRadius: "$radii$xlg",
  background: "$modalBg",
  border: "1px solid #CBCBCB",
  minHeight: "40px",
  my: "$2",
});

export const StyledLimitUl = styled("ul", {
  pt: "$3",
  listStyle:
    "none" /* Use 'disc' for filled circles or 'circle' for empty circles */,
  li: {
    display: "flex",
    marginBottom: "$3",
    fontSize: "$md",
    fontWeight: "$normal",
    color: "$offerHeading",
    "&:before": {
      content: "•" /* Use '•' for filled circles or '○' for empty circles */,
      fontSize: "40px",
      textAlign: "center",
      color: "$priceText" /* Replace with your desired color */,
      lineHeight: "5px",
      display: "inline-block",
      marginTop: "6px",
      width: "20px" /* Adjust the width of the dots as needed */,
    },
  },
});

export const StyledForm = styled("form", {
  width: "100%",
  margin: "0 auto",
  label: {
    fontSize: "$md",
    color: "$textGray5",
    mb: 0,
    "&.check-label": {
      fontSize: "$sm",
      color: "$textGray1",
      fontWeight: "$normal",
      "@md": {
        fontSize: "$lg",
      },
    },
  },
  ".inner-section": {
    ".mb": {
      mb: "$2",
    },
    m: "$space$4 $2",
    width: "100%",
    fontSize: "$lg",
    color: "$black",
    // padding: "0 $5",
    // margin: 0,
  },
  ".filters": {
    "@media (min-width:550px) and (max-width:1050px)": {
      flexWrap: "wrap",
    },
  },
  ".offer-filters": {
    w: "100% !important",
    "@media (min-width:700px)": {
      w: "20% !important",
    },
  },
  ".order-filters": {
    w: "100% !important",
    "@media (min-width:550px) and (max-width:1050px)": {
      w: "48% !important",
    },
  },
});

export const WarningBox = styled(Flex, {
  background: "#04E2AF20",
  // opacity : 0.2,
  padding: "$2",
  minHeight: "100px",
});

export const StyledBorderBox = styled(Flex, {
  mt: "$2",
  opacity: "0.5",
  borderBottom: "1px dotted #A7A7A7",
});

export const StyledTooltip = styled(ReactTooltip, {
  backgroundColor: "#7E8C9F !important",
  // opacity: "1 !important",
  w: "300px !important",
});

export const StyledTextAreaBox = styled(Box, {
  minHeight: "initial",
  background: "$dropdownBg",
  border: "1px solid $borderColor",
  borderRadius: "$radii$xlg",
  "@md": {
    minHeight: "7rem",
  },
});

export const GuideFlex = styled(Flex, {
  "@lg": {
    position: "absolute",
    p: "$4 $8",
  },
  top: "-10px",
  zIndex: 100,
  left: "0px",
  svg: {
    w: "15px",
    h: "15px",
    mr: "$1",
  },
});

export const CurrencyFlex = styled(Flex, {
  borderRadius: "$radii$xlg",
  fontSize: "10px",
  mr: "$4",
  w: "20px",
  h: "20px",
  color: "$white",
});
