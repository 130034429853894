import { z } from "zod";

export const REQUIRED_ERROR = "Field is mandatory";
export const FILES_ERROR = "File is required";
export const NON_NEGATIVE = "Field must be greater than zero";
export const INSUFFICIENT_BALANCE = "Insufficient balance";
export const EMAIL_ERROR = "Enter a valid email";
export const PAYMENT_METHOD_ERROR = "Select atleast one payment method";
export const LIMIT_EXCEED = "Limit exceeded";
export const NO_SPACES_ERROR = "Field cannot contain only spaces";

export const EMAIL_REQUIRED = () => {
  return z.string().email(EMAIL_ERROR);
};

export const DropdownValue = z.object(
  {
    value: z.string(),
  },
  {
    required_error: REQUIRED_ERROR,
  }
);

export const REQUIRED = (message = REQUIRED_ERROR) =>
  z.string().nonempty({ message });

export const REQUIRED_NUMBER = (message = REQUIRED_ERROR) =>
  z
    .number({
      required_error: message,
    })
    .nonnegative({ message: NON_NEGATIVE });

export const VALID_COUNTRY = z.object(
  { label: z.string(), image: z.string() },
  { required_error: REQUIRED_ERROR }
);

export const REQUIRED_NUMBER_NON_DECIMAL = (message = REQUIRED_ERROR) =>
  z
    .number({
      required_error: message,
    })
    .int()
    .nonnegative({ message: NON_NEGATIVE });
