import * as z from "zod";
import { CONFIRM_PASSWORD_ZOD, PASSWORD_ZOD } from "@/utils/validations";
import { Number_REGEX } from "@/utils/regex";

export const VALIDATE_PHONE_NUMBER = z
  .string()
  .regex(Number_REGEX, { message: "Invalid phone number" });

export enum SIGNUP_ENUM {
  PHONE = "PHONE",
  EMAIL = "EMAIL",
}

export const typeEnum = z.enum([SIGNUP_ENUM.PHONE, SIGNUP_ENUM.EMAIL]);

export const registeUserSchema = z
  .object({
    password: PASSWORD_ZOD,
    confirm: CONFIRM_PASSWORD_ZOD,
  })
  .refine((data) => data.password === data.confirm, {
    message: "Passwords do not match.",
    path: ["confirm"],
  });

export const registerSchemaEmail = z.object({
  type: z.literal(typeEnum.enum.EMAIL),
  email: z.string().email("Enter a valid email."),
  password: PASSWORD_ZOD,
  confirm: CONFIRM_PASSWORD_ZOD,
});

export const registerSchemaPhone = z.object({
  type: z.literal(typeEnum.enum.PHONE),
  phone: VALIDATE_PHONE_NUMBER,
  password: PASSWORD_ZOD,
  confirm: CONFIRM_PASSWORD_ZOD,
});

export const registerSchema = z.discriminatedUnion("type", [
  registerSchemaEmail,
  registerSchemaPhone,
]);

export const forgotSchemaEmail = z.object({
  type: z.literal(typeEnum.enum.EMAIL),
  email: z.string().email("Enter a valid email."),
});

export const forgotSchemaPhone = z.object({
  type: z.literal(typeEnum.enum.PHONE),
  phone: VALIDATE_PHONE_NUMBER,
});

export const forgotSchema = z.discriminatedUnion("type", [
  forgotSchemaEmail,
  forgotSchemaPhone,
]);

export type RegisterFormValues = z.infer<typeof registerSchema>;
export type forgotFormValues = z.infer<typeof forgotSchema>;

export const resetPhonePassSchema = z
  .object({
    code: z.string().length(6, "Enter valid code."),
    password: PASSWORD_ZOD,
    confirm: CONFIRM_PASSWORD_ZOD,
  })
  .refine((data) => data.password === data.confirm, {
    message: "Passwords do not match.",
    path: ["confirm"],
  });
export type resetPhoneFormValues = z.infer<typeof resetPhonePassSchema>;

export const loginSchemaEmail = z.object({
  type: z.literal(typeEnum.enum.EMAIL),
  email: z.string().email("Enter a valid email."),
  password: z.string().min(6, "Password is required."),
});

export const loginSchemaPhone = z.object({
  type: z.literal(typeEnum.enum.PHONE),
  phone: VALIDATE_PHONE_NUMBER,
  password: z.string().min(6, "Password is required."),
});

export const loginCombineSchema = z.discriminatedUnion("type", [
  loginSchemaEmail,
  loginSchemaPhone,
]);

export type LoginFormValues = z.infer<typeof loginCombineSchema>;

export type IRefineValues = {
  password: string;
  identifier: string;
  captchaToken: string;
};
