import * as z from "zod";

const passwordPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{6,}$/;

const passLengthMessage = "Password limit: 16 characters.";

export const passwordValidation = [
  passwordPattern,
  "Password must be at least 6 characters long with one uppercase letter, one lowercase letter, one special character, and one numeral",
] as const;

export const PASSWORD_ZOD = z
  .string()
  .nonempty({ message: "Password is required." })
  .max(16, passLengthMessage)
  .regex(...passwordValidation);
export const CONFIRM_PASSWORD_ZOD = z
  .string()
  .min(1, "Confirm password is required.")
  .max(16, passLengthMessage);
