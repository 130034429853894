import { styled } from "@/theme";
import { ToastContainer } from "react-toastify";
import { Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StyledToastContainer = styled(ToastContainer, {
  zIndex: 99999,
});
export const ToastProvider = () => {
  return (
    <StyledToastContainer
      limit={1}
      position="bottom-right"
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      transition={Flip}
      theme="colored"
    />
  );
};
