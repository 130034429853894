import { createContext, useCallback, useEffect, useState } from "react";
import { ContextApi, ContextData, ProviderState } from "./types";
import { LS_KEY, fetchLocale, getLanguageCodeFromLS } from "./helpers";
import { ILang } from "@/components/Language/type";
import { Language } from "@/utils/languages";

const initialState: ProviderState = {
  isFetching: true,
  currentLanguage: Language[0],
};

// Export the translations directly
export const languageMap = new Map<ILang["code"], Record<string, string>>();

export const LanguageContext = createContext<ContextApi | undefined>(undefined);

export const LanguageProvider = ({ children }: any) => {
  const [state, setState] = useState<ProviderState>(() => {
    const codeFromStorage = getLanguageCodeFromLS();
    return {
      ...initialState,
      currentLanguage:
        Language.find((lang) => lang.code === codeFromStorage) || Language[0],
    };
  });
  const { currentLanguage } = state;

  useEffect(() => {
    const fetchInitialLocales = async () => {
      const codeFromStorage = getLanguageCodeFromLS();
      if (codeFromStorage) {
        const currentLocale = await fetchLocale(codeFromStorage);
        languageMap.set(codeFromStorage, { ...currentLocale });

        const language = Language.find((lang) => lang.code === codeFromStorage);
        if (language) {
          language &&
            setState({
              currentLanguage: language,
              isFetching: false,
            });
        }
      }
    };

    fetchInitialLocales();
  }, [setState]);

  const setLanguage = async (language: ILang) => {
    const codeFromStorage = getLanguageCodeFromLS();
    if (codeFromStorage === language.code) return;
    const locale = await fetchLocale(language.code);
    // Merge the EN locale to ensure that any locale fetched has all the keys
    languageMap.set(language.code, { ...locale });
    localStorage.setItem(LS_KEY, language.code);

    setState((prevState) => ({
      ...prevState,
      isFetching: false,
      currentLanguage: language,
    }));
  };

  const translate = useCallback(
    (key: string, data?: ContextData) => {
      const translationSet = languageMap.has(currentLanguage?.code)
        ? languageMap.get(currentLanguage?.code)
        : languageMap.get(Language[0].code);
      const translatedText = translationSet ? translationSet[key] : key;

      // Check the existence of at least one combination of %%, separated by 1 or more non space characters
      const includesVariable = translatedText?.match(/%\S+?%/gm);

      if (includesVariable && data) {
        let interpolatedText = translatedText;
        Object.keys(data).forEach((dataKey) => {
          const templateKey = new RegExp(`%${dataKey}%`, "g");
          interpolatedText = interpolatedText.replace(
            templateKey,
            data[dataKey].toString()
          );
        });

        return interpolatedText;
      }

      return translatedText;
    },
    [currentLanguage]
  );

  return (
    <LanguageContext.Provider value={{ ...state, setLanguage, t: translate }}>
      {children}
    </LanguageContext.Provider>
  );
};
