import { useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";

const socketBaseUrl = import.meta.env.VITE_SOCKET_URL;

const useSubscription = () => {
  const [socket, setSocket] = useState<Socket | null>(null);
  useEffect(() => {
    // const tokenCookie = document.cookie;
    // const match = tokenCookie.match(/Authentication=([^&;]*)/);
    const token = localStorage.getItem("Authentication");

    const newSocket = io(socketBaseUrl, {
      extraHeaders: { authorization: token ? token : "" },
    });
    setSocket(newSocket);
    // Clean up the socket connection when the component unmounts
    return () => {
      newSocket.disconnect();
    };
  }, []);
  return socket;
};

export default useSubscription;
