import React, { createContext, useContext, useState, ReactNode } from "react";

interface State {
  res: any;
  refinedValues: any;
}

interface AuthContextType {
  routeState: State | null;
  setRouteState: (state: State | null) => void;
}

const RouteContext = createContext<AuthContextType | undefined>(undefined);

export const RouteContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [routeState, setRouteState] = useState<State | null>(null);

  return (
    <RouteContext.Provider value={{ routeState, setRouteState }}>
      {children}
    </RouteContext.Provider>
  );
};

export const useRouteContext = () => {
  const context = useContext(RouteContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
