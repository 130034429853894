import ReactDOM from "react-dom/client";
import Providers from "./Providers";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import React from "react";
import * as Sentry from "@sentry/react";

const App = React.lazy(() => import("./App.tsx"));

Sentry.init({
  dsn: "https://bfe7f11df7bfca57e7f7a0dc7e7085a4@o4507088629465088.ingest.us.sentry.io/4507095039344640",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "https://www.localtraders.finance",
    "https://apis.localtraders.finance/api",
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Ensure the DOM content is loaded before rendering
document.addEventListener("DOMContentLoaded", () => {
  // Use createRoot to enable Concurrent Mode
  const rootElement = document.getElementById("root");
  if (!rootElement) {
    throw new Error("Root element not found in the document.");
  }
  const root = ReactDOM.createRoot(rootElement);

  // Render the application wrapped in Providers for global context
  root.render(
    <Providers>
      <App />
    </Providers>
  );
});
