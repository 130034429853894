import { P2P_ACTION } from "@/components/BuySaleWidget/type";
import { ITokenList } from "@/service/type";
import { createAction } from "@reduxjs/toolkit";

export const setTokens = createAction<ITokenList>("token/setTokens");
export const setP2pAction = createAction<P2P_ACTION>("token/setP2pAction");
export const setTokenList = createAction<ITokenList[]>("token/setTokenList");
export const setTokenLoading = createAction<boolean>("token/setTokenLoading");
export const setIsDirectRoute = createAction<boolean>("token/setIsDirectRoute");
export const setIsShowActivate = createAction<boolean>(
  "token/setIsShowActivate"
);
