import { Language } from "@/utils/languages";

export const LS_KEY = "gamefi_language_key";
//todo : when lang folder added on top
const apiBaseURL = import.meta.env.VITE_API_BASE_URL;

export const fetchLocale = async (locale: string) => {
  const response = await fetch(`${apiBaseURL}/lang/${locale}.json`);
  if (!response.ok) {
    //default language
    const resEn = await fetch(`${apiBaseURL}/lang/en.json`);
    const data = await resEn.json();
    return data;
  }
  const data = await response.json();
  return data;
};

export const getLanguageCodeFromLS = () => {
  try {
    const codeFromStorage = localStorage.getItem(LS_KEY);
    return codeFromStorage || Language[0].code;
  } catch {
    return Language[0].code;
  }
};
