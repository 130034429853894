import { createReducer } from "@reduxjs/toolkit";
import { setFiat, setRoute } from "./actions";
import { ICommonState } from "../token/type";

export const initialState: ICommonState = {
  route: "",
  currency: {
    code: "USD",
    country: "",
    createdAt: "",
    updatedAt: "",
    name: "United States dollar",
    label: "United States dollar",
    id: 7,
    color: "#0137A3",
    symbol: "$",
  },
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setRoute, (state, { payload }) => {
      state.route = payload.route;
    })
    .addCase(setFiat, (state, { payload }) => {
      state.currency = payload;
    })
);
