import {
  AuthUser,
  LoginResponse,
  UserModule,
  UserResponse,
} from "@/features/auth";
import storage from "@/utils/storage";
import { apiClient } from "./axios";
import axios from "axios";
import { VERIFICATION_ENUM } from "@/features/verification/types";
import { logoutCall } from "@/features/auth/api/logoutCall";

export async function loadUser() {
  try {
    let user = null;
    // const tokenCookie = document.cookie;
    const token = localStorage.getItem("Authentication");
    // const match = tokenCookie.match(/Authentication=([^&]*)/);
    if (token) {
      const resp = await apiClient.get<AuthUser>(UserModule.currentUser);
      user = resp;
    }
    return user;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response?.data;
    } else {
      console.error(error);
    }
  }
}
export type IOTP = {
  token: string;
  otp: string;
  type: VERIFICATION_ENUM;
};

export type LoginCredentialsDTO = {
  identifier: string;
  password: string;
  captchaToken: string;
  otps?: IOTP[];
};

export async function loginFn(data: LoginCredentialsDTO) {
  const resp = await apiClient.post<LoginResponse>(UserModule.login, data);
  if (!resp.message) {
    const { token } = resp;
    localStorage.setItem("Authentication", token);
  }
  return resp;
}

export type RegisterCredentialsDTO = {
  identifier: string;
  password: string;
  captchaToken: string;
};

export async function registerFn(data: RegisterCredentialsDTO) {
  const resp = await apiClient.post<UserResponse>(UserModule.register, data);
  return resp;
}

export async function logoutFn() {
  await logoutCall();
  storage.clearToken();
  localStorage.clear();
}
