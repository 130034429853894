import { createContext, useMemo } from "react";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryKey,
  QueryStatus,
  UseMutateAsyncFunction,
} from "@tanstack/react-query";
import { loginFn, logoutFn, registerFn, loadUser } from "@/lib/auth";
import { User } from "@/features/auth";
import { Flex } from "@/components/elements";
// import { AxiosError } from "axios";
import animationData from "@/lottie/loading.json";
import Lottie from "lottie-react";
import useSubscription from "@/hooks/useSubscription";

// TODO: improve types
export interface AuthContextValue {
  user: User | null | undefined;
  authStatus: QueryStatus;
  error: unknown;
  refetchUser: () => void;
  isError: boolean;
  login: (data: any, callbacks?: any) => Promise<any>;
  isLoggingIn: boolean;
  logout: UseMutateAsyncFunction<void, unknown, void, unknown>;
  isLoggingOut: boolean;
  register: (data: any, callbacks?: any) => Promise<any>;
  isRegistering: boolean;
  isUserLoading: boolean;
}

export const AuthContext = createContext<AuthContextValue | null>(null);
AuthContext.displayName = "AuthContext";

export interface AuthProviderProps {
  children: React.ReactNode;
  authKey?: QueryKey;
  waitInitial?: boolean;
}

export function AuthProvider(props: AuthProviderProps) {
  const { children, authKey = ["auth-user"], waitInitial = true } = props;
  const queryClient = useQueryClient();
  const socket = useSubscription();

  const {
    data: user,
    error,
    status,
    isError,
    refetch,
    isLoading: isUserLoading,
  } = useQuery(authKey, loadUser);

  const loginMutation = useMutation(loginFn);

  const registerMutation = useMutation(registerFn);

  const logoutMutation = useMutation(logoutFn, {
    onSuccess: () => {
      queryClient.clear();
      socket && socket.disconnect();
    },
  });

  const value = useMemo(
    () => ({
      user,
      authStatus: status,
      error,
      isError,
      refetchUser: refetch,
      login: loginMutation.mutateAsync,
      isLoggingIn: loginMutation.isLoading,
      logout: logoutMutation.mutateAsync,
      isLoggingOut: logoutMutation.isLoading,
      register: registerMutation.mutateAsync,
      isRegistering: registerMutation.isLoading,
      isUserLoading,
    }),
    [
      user,
      status,
      isError,
      error,
      refetch,
      loginMutation.mutateAsync,
      loginMutation.isLoading,
      logoutMutation.mutateAsync,
      logoutMutation.isLoading,
      registerMutation.mutateAsync,
      registerMutation.isLoading,
      isUserLoading,
    ]
  );

  if (status === "success" || !waitInitial) {
    return (
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
  }

  if (status === "loading") {
    return (
      <Flex
        css={{ w: "100vw", h: "100vh", background: "$bodyBg" }}
        justify={"center"}
        align={"center"}
      >
        <Lottie className="lottie-container" animationData={animationData} />
      </Flex>
    );
  }

  if (error) {
    console.log("AuthContext", error);
    return <div>Something went wrong</div>;
  }

  return <div>Unhandled status: {status}</div>;
}
