import React from "react";
import { Box, Stepper, Step, ChakraProvider } from "@chakra-ui/react";
import {
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
} from "@chakra-ui/react";
import { styled } from "@/theme";
import { Flex } from ".";
import { ReactComponent as ArrowDown } from "@/assets/offer/Arrow.svg";
import { STEPPER_STEPS } from "@/features/offer/Create/types";

interface StepProps {
  label: string;
}

interface ChakraStepperProps {
  steps: StepProps[];
  activeStep: number;
  setStep: (s: STEPPER_STEPS) => void;
}

const ChakraStepper: React.FC<ChakraStepperProps> = ({
  steps,
  activeStep,
  setStep,
}) => {
  // const isMobile = useMediaQuery(768);

  return (
    <ChakraProvider>
      {/* <CSSReset /> */}
      <Flex css={{ w: "80%" }} direction={"column"}>
        <Stepper index={activeStep}>
          {steps.map((step, index) => (
            <StyledSteps
              direction={"column"}
              key={index}
              css={{ pointerEvents: "none" }}
              onClick={() => setStep(index)}
            >
              <Step>
                <Flex direction={"column"} align={"center"}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>
                  <Box
                    flexShrink="0"
                    css={{
                      width: "fit-content",
                      position: "absolute",
                      top: "50%",
                      left: 0,
                      transform: "translate(-40%, 50%)",
                    }}
                  >
                    <StyledStepTitle>{step.label}</StyledStepTitle>
                  </Box>
                  {activeStep === index && <StyledArrow />}
                </Flex>

                <StepSeparator />
              </Step>

              {/* <Box
                flexShrink="0"
                css={{
                  width: "fit-content",
                  position: "relative",
                  marginLeft: isMobile
                    ? "none"
                    : index === steps.length - 1
                      ? "-35px"
                      : "-85px",
                }}
              >
                <StyledStepTitle>{step.label}</StyledStepTitle>
                {activeStep === index && <StyledArrow />}
              </Box> */}
            </StyledSteps>
          ))}
        </Stepper>
      </Flex>
    </ChakraProvider>
  );
};

const StyledSteps = styled(Flex, {
  cursor: "pointer",
  width: "100%",
  mt: "$4",
  //style to last child
  "&:last-child": {
    w: "fit-content",
  },
  ".chakra-step__indicator": {
    //stype on date status [data-status=complete]
    "&[data-status=active]": {
      backgroundColor:
        "#9C6AFD" /* Replace with the desired background color for active state */,
      borderColor: "#9C6AFD",
      color: "white",
    },
    "&[data-status=complete]": {
      backgroundColor:
        "#9C6AFD" /* Replace with the desired background color for active state */,
      borderColor: "#9C6AFD",
      color: "white",
    },
    "&[data-status=incomplete]": {
      backgroundColor:
        "#E1E1E1" /* Replace with the desired background color for pending state */,
      color: "white",
    },
  },
  ".chakra-step__separator": {
    "&[data-status=active]": {
      background: "#C0C0C0",
    },
    "&[data-status=complete]": {
      background: "#9C6AFD",
    },
    "&[data-status=incomplete]": {
      background: "#C0C0C0",
    },
  },
});

const StyledStepTitle = styled(StepTitle, {
  whiteSpace: "nowrap",
  mt: "$2",
  color: "$textDark",
  display: "none",
  "@md": {
    display: "block",
  },
});

const StyledArrow = styled(ArrowDown, {
  position: "absolute",
  // left: "41%",
  left: 2,
  top: 60,
  mt: "$2",
});

export default ChakraStepper;
